import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import "./SubscriptionsPage.scss";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { stripeService } from "../../services/stripe-service";
import { authentication } from "../../services/authentication";
import { useEffect } from "react";

const Page = () => {
  const toast = useToast();
  const [customer, loadingCustomer, setCustomer] = useDataFetcher({
    serviceCall: () =>
      stripeService.getCustomer(authentication.getUser()?.email),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [subscriptions, loadingSubscriptions] = useDataFetcher({
    serviceCall: () => stripeService.getCustomerSubscriptions(customer.id),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
    conditionForExecution: customer !== undefined,
    dependencies: [customer],
  });

  const portalSession = async () => {
    const body = await stripeService.createPortalSession(customer.id);
    window.location.href = body.url;
  };

  const newUser = async () => {
    await stripeService.createCustomer(authentication.getUser()?.email);
    setCustomer(
      await stripeService.getCustomer(authentication.getUser()?.email)
    );
  };

  useEffect(() => {
    if (customer !== undefined && customer === "") {
      newUser();
    }
  }, [customer]);

  return (
    <Box className="SubscriptionsPage">
      {loadingCustomer ||
      loadingSubscriptions ||
      !subscriptions ||
      customer === "" ? (
        <Flex justifyContent={"center"} alignItems={"center"} w={"100%"}>
          <Spinner thickness="0.25rem" color="#272349" size={"xl"} />
        </Flex>
      ) : (
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          flexDir={"column"}
          gap={"1rem"}
          w={"100%"}
        >
          <Text fontSize={"2xl"}>
            You have {customer !== "" ? subscriptions.length : 0} active
            subscriptions
          </Text>
          <Button colorScheme="blue" onClick={() => portalSession()}>
            Manage your subscriptions
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export const SubscriptionsPage = withAuthProtection(Page);
