import { Box, Image, Flex, Text, Button } from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import data from "../../../../config/config.json";

interface PaletCardProps {
  unit_id: string;
  entry_date: string;
  description: string;
  photos: string | string[];
}

export const PaletCard: FunctionComponent<PaletCardProps> = (props) => {
  const navigate = useNavigate();
  const getPhoto = () => {
    let photo: string = "";
    if (typeof props.photos === "string") {
      photo = props.photos;
      if (props.photos.length === 0) {
        photo = data.defaultPhoto;
      }
      if (!photo.includes("data:image")) {
        photo = "data:image/jpeg;base64," + photo;
      }
    } else {
      if (props.photos.length === 0) {
        photo = data.defaultPhoto;
      } else {
        if (typeof props.photos[0] === "string") {
          photo = props.photos[0];
          if (props.photos[0].length === 0) {
            photo = data.defaultPhoto;
          }
          if (!photo.includes("data:image")) {
            photo = "data:image/jpeg;base64," + photo;
          }
        } else {
          photo = "/" + (props.photos[0] as Express.Multer.File).path;
        }
      }
    }
    return photo;
  };

  return (
    <Flex
      bg={"white"}
      border={"1px solid black"}
      padding={"1rem"}
      gap={"1rem"}
      flexDir={"column"}
      justifyContent={"space-between"}
      width={"100%"}
      id={`storage-${props.unit_id}`}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Box border={"1px solid black"} padding={"0.5rem"} fontWeight={600}>
          {props.unit_id}
        </Box>
        <Text>{new Date(props.entry_date).toLocaleString()}</Text>
      </Flex>
      <Image src={getPhoto()} />
      <Text>{props.description}</Text>
      <Flex justifyContent={"end"}>
        <Button
          variant="grey"
          onClick={() => navigate(`/details/${props.unit_id}`)}
        >
          See More
        </Button>
      </Flex>
    </Flex>
  );
};
