import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { HomePage } from "./Pages/HomePage/HomePage";
import { LoginPage } from "./Pages/LoginPage/LoginPage";
import { SignupPage } from "./Pages/SignupPage/SignupPage";
import { LoginCallbackPage } from "./Pages/LoginCallBackPage/LoginCallbackPage";
import { PageNotFoundPage } from "./Pages/PageNotFound/PageNotFound";
import { EmptyLayout } from "./Layouts/EmptyLayout/EmptyLayout";
import { MainLayout } from "./Layouts/MainLayout/MainLayout";
import { SubscriptionsPage } from "./Pages/SubscriptionsPage/SubscriptionsPage";
import { LocationPage } from "./Pages/LocationPage/LocationPage";
import { DetailsPage } from "./Pages/DetailsPage/DetailsPage";
import { HistoryPage } from "./Pages/HistoryPage/HistoryPage";
import { PastDetailsPage } from "./Pages/PastDetailsPage/PastDetailsPage";

export const Kernel = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="home" element={<HomePage />} />
          <Route path="location" element={<LocationPage />} />
          <Route path="subscriptions" element={<SubscriptionsPage />} />
          <Route path="details/:unit_id" element={<DetailsPage />} />
          <Route path="past-details" element={<PastDetailsPage />} />
          <Route path="history" element={<HistoryPage />} />
          <Route index element={<HomePage />} />
        </Route>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="login-callback" element={<LoginCallbackPage />} />
        </Route>
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
