import {
  Box,
  Text,
  Flex,
  Grid,
  Image,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import "./DetailsPage.scss";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { storageService } from "../../services/storage-service";
import { authentication } from "../../services/authentication";
import { useEffect, useState } from "react";
import { IStorage } from "../../data/product";
import { PageNotFoundPage } from "../PageNotFound/PageNotFound";

const styleTemplateColumns = {
  base: "repeat(1, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

const styleColumnGap = {
  base: "1rem",
  xl: "2.5rem",
  "2xl": "5rem",
};

const Page = () => {
  const toast = useToast();
  const [storage, loadingStorage] = useDataFetcher({
    serviceCall: () =>
      storageService.getStorage(authentication.getUser()?.email),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [palet, setPalet] = useState<IStorage | undefined>();

  useEffect(() => {
    const unitId = window.location.href.toString().split("details/")[1];
    setPalet(storage?.filter((x) => x.unit_id === unitId)[0]);
  }, [storage]);

  const getTitle = () => {
    if (!palet) {
      return;
    }
    if (palet.unit_id.charAt(0) === "D") {
      return "Dry Storage";
    } else if (palet.unit_id.charAt(0) === "F") {
      return "Frozen Storage";
    } else if (palet.unit_id.charAt(0) === "R") {
      return "Refrigerated Storage";
    } else {
      return "Storage";
    }
  };

  const getPhoto = (currentPhoto: string) => {
    let photo = currentPhoto;
    if (!currentPhoto.includes("data:image")) {
      photo = "data:image/jpeg;base64," + photo;
    }
    return photo;
  };

  const getColor = () => {
    if (!palet) {
      return;
    }
    if (palet.unit_id.charAt(0) === "D") {
      return "#A8890F";
    } else if (palet.unit_id.charAt(0) === "F") {
      return "#1CAAF0";
    } else if (palet.unit_id.charAt(0) === "R") {
      return "#C41FBF";
    } else {
      return "black";
    }
  };

  const hasPhoto = () => {
    if (!palet?.current?.photo) {
      return false;
    }
    if (Array.isArray(palet.current.photo)) {
      return palet.current.photo.length > 0;
    }
    return true;
  };

  const hasDocument = () => {
    if (!palet?.current?.document) {
      return false;
    }
    if (Array.isArray(palet.current.document)) {
      return palet.current.document.length > 0;
    }
    return true;
  };

  return !loadingStorage ? (
    palet && palet.current ? (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box className="DetailsPage" gap={{ base: "2rem", xl: "4rem" }}>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            w={"100%"}
          >
            <Flex gap={"1rem"} alignItems={"center"} w={"100%"}>
              <Text fontSize={"4xl"}>{palet.unit_id}</Text>
              <Flex
                alignItems={"center"}
                border={`1px solid ${getColor()}`}
                color={getColor()}
                padding={"0.5rem"}
                height={"2.5rem"}
                fontWeight={600}
              >
                {getTitle()}
              </Flex>
            </Flex>
            <Text fontSize={"xl"} whiteSpace={"nowrap"}>
              {new Date(palet.current.entry_date).toLocaleString()}
            </Text>
          </Flex>
          <Flex w={"100%"}>
            <Text fontSize={"2xl"}>{palet.current.description}</Text>
          </Flex>
          <Flex w={"100%"} flexDir={"column"} gap={"2rem"}>
            <Text fontSize={"4xl"}>{hasPhoto() ? "Photos" : "No Photos"}</Text>
            <Grid
              templateColumns={styleTemplateColumns}
              rowGap={"2rem"}
              columnGap={styleColumnGap}
            >
              {palet.current.photo &&
                (typeof palet.current.photo === "string" ? (
                  <Image
                    src={getPhoto(palet.current.photo)}
                    w={"400px"}
                    h={"250px"}
                    objectFit={"fill"}
                  />
                ) : (
                  (palet.current.photo as string[]).map((photo: string) => {
                    return (
                      <Image
                        src={getPhoto(photo)}
                        w={"100%"}
                        h={"250px"}
                        objectFit={"fill"}
                      />
                    );
                  })
                ))}
            </Grid>
          </Flex>
          <Flex w={"100%"} flexDir={"column"} gap={"2rem"}>
            <Text fontSize={"4xl"}>
              {hasDocument() ? "Documents" : "No Documents"}
            </Text>
            <Grid
              templateColumns={styleTemplateColumns}
              rowGap={"2rem"}
              columnGap={styleColumnGap}
            >
              {palet.current.document &&
                (Array.isArray(palet.current.document) ? (
                  palet.current.document.map((document) => {
                    return (
                      <Flex className="DetailsPageDocumentContainer">
                        <a
                          style={{ fontSize: "1rem" }}
                          download={document.documentName}
                          href={document.document}
                        >
                          {document.documentName}
                        </a>
                      </Flex>
                    );
                  })
                ) : (
                  <Flex className="DetailsPageDocumentContainer">
                    <a
                      style={{ fontSize: "1rem" }}
                      download={palet.current.document.documentName}
                      href={palet.current.document.document}
                    >
                      {palet.current.document.documentName}
                    </a>
                  </Flex>
                ))}
            </Grid>
          </Flex>
          <Flex
            w={"100%"}
            flexDir={"column"}
            gap={"2rem"}
            paddingBottom={"2rem"}
          >
            <Text fontSize={"4xl"}>Signature</Text>
            <Image
              src={palet.current.signature}
              w={"9.375rem"}
              h={"9.375rem"}
            />
          </Flex>
        </Box>
      </Box>
    ) : (
      <PageNotFoundPage />
    )
  ) : (
    <Box
      h={"100vh"}
      overflowY={"auto"}
      w={"100%"}
      display={"flex"}
      justifyContent={"center"}
    >
      <Box className="DetailsPage" gap={{ base: "2rem", xl: "4rem" }}>
        <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
          <Skeleton>
            <Flex gap={"1rem"} alignItems={"center"} w={"100%"}>
              <Text fontSize={"4xl"}>Loading</Text>
              <Flex
                alignItems={"center"}
                border={`1px solid ${getColor()}`}
                color={getColor()}
                padding={"0.5rem"}
                height={"2.5rem"}
                fontWeight={600}
              >
                Loading
              </Flex>
            </Flex>
          </Skeleton>
          <Skeleton>
            <Text fontSize={"xl"} whiteSpace={"nowrap"}>
              Loading
            </Text>
          </Skeleton>
        </Flex>
        <Skeleton>
          <Text fontSize={"2xl"}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam
          </Text>
        </Skeleton>
        <Flex w={"100%"} flexDir={"column"} gap={"2rem"}>
          <Text fontSize={"4xl"}>Photos</Text>
          <Grid
            templateColumns={styleTemplateColumns}
            rowGap={"2rem"}
            columnGap={styleColumnGap}
          >
            {new Array(6).fill(0).map(() => {
              return <Skeleton h={"16rem"} w={"100%"} />;
            })}
          </Grid>
        </Flex>
        <Flex w={"100%"} flexDir={"column"} gap={"2rem"}>
          <Text fontSize={"4xl"}>Documents</Text>
          <Grid
            templateColumns={styleTemplateColumns}
            rowGap={"2rem"}
            columnGap={styleColumnGap}
          >
            {new Array(3).fill(0).map(() => {
              return <Skeleton h={"16rem"} w={"100%"} />;
            })}
          </Grid>
        </Flex>
      </Box>
    </Box>
  );
};

export const DetailsPage = withAuthProtection(Page);
