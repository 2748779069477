import {
  Box,
  Text,
  Flex,
  Grid,
  Skeleton,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { authentication } from "../../services/authentication";
import { storageService } from "../../services/storage-service";
import { PaletPastCard } from "./components/PaletPastCard";
import { ManagementPage } from "../../components/commons/ManagementPage/ManagementPage";
import { IoCloudOfflineOutline } from "react-icons/io5";
import "./HistoryPage.scss";

const styleTemplateColumns = {
  base: "repeat(1, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

const styleColumnGap = {
  base: "1rem",
  xl: "2.5rem",
  "2xl": "5rem",
};

const Page = () => {
  const toast = useToast();
  const [storage, loadingStorage] = useDataFetcher({
    serviceCall: () =>
      storageService.getPastStorageByEmail(authentication.getUser()?.email),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });

  const renderPalets = () => {
    if (!storage || storage.length === 0) {
      return (
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          gap={"0.5rem"}
          paddingTop={"2rem"}
        >
          <Icon boxSize={12} as={IoCloudOfflineOutline} />
          <Text>There is no data to display</Text>
        </Flex>
      );
    }

    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {storage.map((pallet) => {
          if (pallet.past) {
            return pallet.past.map((past) => {
              if (past.client_id === authentication.getUser()?.email) {
                return (
                  <PaletPastCard
                    key={`${pallet.unit_id} ${past.entry_date}`}
                    unit_id={pallet.unit_id}
                    entry_date={past.discharge_date}
                    description={past.description}
                    photos={past.photo || ""}
                    pallet={past}
                  />
                );
              }
            });
          }
        })}
      </Grid>
    );
  };

  const renderSkeletons = () => {
    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {new Array(6).fill(0).map(() => {
          return <Skeleton h={"28rem"} w={"100%"} />;
        })}
      </Grid>
    );
  };

  const getPageContent = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="HistoryPage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "4rem" }}
          maxW={"1500px"}
        >
          <Box>
            <Text fontSize="5xl" fontWeight={700}>
              History
            </Text>
          </Box>
          <Box>
            <Text fontSize="3xl" fontWeight={700} marginBottom={"1rem"}>
              Past Usage
            </Text>
            <Flex flexDir={"column"} gap={"2rem"} paddingBottom={"2rem"}>
              {loadingStorage ? renderSkeletons() : <>{renderPalets()}</>}
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <ManagementPage
      title={"History"}
      path={""}
      isLoading={false}
      previousLocation={""}
    >
      {getPageContent()}
    </ManagementPage>
  );
};

export const HistoryPage = withAuthProtection(Page);
