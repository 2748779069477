import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HiHome, HiLocationMarker } from "react-icons/hi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaHistory, FaMoneyBillWave } from "react-icons/fa";

interface NavButtonProps extends ButtonProps {
  icon: string;
  label: string;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, ...buttonProps } = props;
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const getIcon = (key: string) => {
    if (key === "home") {
      return HiHome;
    } else if (key === "location") {
      return HiLocationMarker;
    } else if (key === "subscriptions") {
      return FaMoneyBillWave;
    } else if (key === "history") {
      return FaHistory;
    } else {
      return HiOutlineArrowSmRight;
    }
  };
  return (
    <Button
      variant="grey"
      justifyContent={isDesktop ? "start" : "center"}
      {...buttonProps}
    >
      <HStack spacing="3">
        <Icon as={getIcon(icon)} />
        {isDesktop && <Text>{label}</Text>}
      </HStack>
    </Button>
  );
};
