import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface ProductCardProps {
  title: string;
  tags: string[];
  onBuy: () => void;
}

export const ProductCard: FunctionComponent<ProductCardProps> = (props) => {
  return (
    <Flex
      bg={"white"}
      border={"1px solid black"}
      padding={"1.5rem 1rem"}
      paddingBottom={"2.5rem"}
      flexDir={"column"}
      justifyContent={"space-between"}
      width={"100%"}
    >
      <Text fontSize="xl" fontWeight={700}>
        {props.title}
      </Text>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        gap={"0.5rem"}
        marginTop={"1rem"}
        marginBottom={"2rem"}
      >
        {props.tags.map((tag) => {
          return (
            <Box border={"1px solid black"} padding={"0.5rem"} fontWeight={600}>
              <a href={`#storage-${tag}`}>{tag}</a>
            </Box>
          );
        })}
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant="outline"
          padding={"1rem"}
          border={"1px solid black"}
          color={"black"}
          fontWeight={700}
          borderRadius={"2rem"}
          w={"90%"}
          minW={"220px"}
          onClick={() => props.onBuy()}
        >
          Buy {props.title}
        </Button>
      </Box>
    </Flex>
  );
};
