import { IProduct } from "../data/product";
import { BaseService } from "./api-clients/base-service";
import { BearerTokenApiClient } from "./api-clients/bearer-token-api-client";

export class ProductService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, "catalog");
  }

  getProducts(): Promise<IProduct[]> {
    const url = this.url("/products");
    return this.apiClient.get(url);
  }
}
export const productService = new ProductService(new BearerTokenApiClient());
