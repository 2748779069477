import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authentication } from '../../services/authentication';
import { Spinner } from '@chakra-ui/react';

const Page = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const completeLogin = async () => {
      try {
        await authentication.handleRedirectCallback();
        navigate('/home');
      } catch (err) {
        console.error(err);
      }
    };
    completeLogin();
  }, [navigate]);
  return <Spinner size="xl" />;
};

export const LoginCallbackPage = Page;
