import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import { FunctionComponent } from "react";

export interface LocationFinderProps {
  data: {
    lat: number;
    lng: number;
  };
}

export const LocationFinder: FunctionComponent<LocationFinderProps> = (
  props
) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyDq3gOwCIDDOqZU8v9afpPJar8f-mD1lMk">
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={props.data}
        zoom={12}
      >
        <MarkerF position={props.data} />
      </GoogleMap>
    </LoadScript>
  );
};
