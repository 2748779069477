export const configuration = {
  cedApiKey: "8c2fedbdd6d4642b3d78ffaf516e91808057f30e5d95f5f24d92d03afddedbcd6b2d8324068c164835a9e7588da5986257bb",
  environment: "production",
  version: "1.0.47",
  businessMetadata: {
    lat: "33.936320",
    lng: "-84.210060",
    direction: "301 Fairmont Dr Norcross GA 30071",
  },
  heroku: {
    hostingUrl: "https://api.frio.ai",
  },
  userRegistry: {
    domain: "auth.frio.ai",
    managementClientId: "wewMXRvLnSbjne7nWBvoNon2HSy13VvW",
    managementClientSecret: "oQZSe30Q8W36XBoQbYxkcLktyde8zZIa-xdbE8P5SxNOSIMhCdKbsHDWhG0BJRYF",
    audience: "https://demy.frio.ai",
    clientId: "wewMXRvLnSbjne7nWBvoNon2HSy13VvW",
  },
};