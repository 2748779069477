import { Box, Text, Flex, Grid, Skeleton, useToast } from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import "./HomePage.scss";
import { productService } from "../../services/product-service";
import { ProductCard } from "./components/ProductCard";
import { configuration } from "../../../config/configuration";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { stripeService } from "../../services/stripe-service";
import { authentication } from "../../services/authentication";
import { storageService } from "../../services/storage-service";
import { PaletCard } from "./components/PaletCard";
import { ManagementPage } from "../../components/commons/ManagementPage/ManagementPage";

const styleTemplateColumns = {
  base: "repeat(1, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

const styleColumnGap = {
  base: "1rem",
  xl: "2.5rem",
  "2xl": "5rem",
};

const Page = () => {
  const toast = useToast();
  const [catalog, loadingCatalog] = useDataFetcher({
    serviceCall: () => productService.getProducts(),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [storage, loadingStorage] = useDataFetcher({
    serviceCall: () =>
      storageService.getStorage(authentication.getUser()?.email),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [customer, loadingCustomer] = useDataFetcher({
    serviceCall: () =>
      stripeService.getCustomer(authentication.getUser()?.email),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });

  const isInsideMember = (email?: string) => {
    if (!email) {
      return false;
    }
    return (
      email.slice(email.indexOf("@") + 1, email.indexOf(".")) === "merthin"
    );
  };

  const checkoutSession = async (priceId: string, name: string) => {
    if (
      (authentication.getUser()?.email === "sebas@cosmosrobotics.com" ||
        isInsideMember(authentication.getUser()?.email)) &&
      configuration.userRegistry.domain === "demy-frioai-prod.us.auth0.com"
    ) {
      let adminPriceId = "";
      if (name === "Refrigerated Storage") {
        adminPriceId = "price_1NifpGHWqRYV2IWxej6DQEVR";
      } else if (name === "Dry Storage") {
        adminPriceId = "price_1NifstHWqRYV2IWxVIuDx2qD";
      } else {
        adminPriceId = "price_1NiftGHWqRYV2IWxFZ7FVVOA";
      }
      const body = await stripeService.createCheckoutSession(
        adminPriceId,
        customer.id
      );
      window.location.href = body.url;
    } else {
      const body = await stripeService.createCheckoutSession(
        priceId,
        customer.id
      );
      window.location.href = body.url;
    }
  };

  const renderProducts = () => {
    if (!catalog) {
      return;
    }
    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"1rem"}
        columnGap={styleColumnGap}
      >
        {catalog.map((product) => {
          return (
            <ProductCard
              key={product.name}
              title={product.name}
              tags={
                storage
                  ?.filter(
                    (x) => x.unit_id.charAt(0) === product.name.charAt(0)
                  )
                  .map((x) => x.unit_id) || []
              }
              onBuy={() => checkoutSession(product.priceId, product.name)}
            />
          );
        })}
      </Grid>
    );
  };

  const renderPalets = () => {
    if (!storage) {
      return;
    }

    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {storage.map((palet) => {
          if (palet.current) {
            return (
              <PaletCard
                key={palet.unit_id}
                unit_id={palet.unit_id}
                entry_date={palet.current.entry_date}
                description={palet.current.description}
                photos={palet.current.photo || ""}
              />
            );
          }
        })}
      </Grid>
    );
  };

  const renderSkeletons = () => {
    return (
      <>
        <Grid
          templateColumns={styleTemplateColumns}
          rowGap={"2rem"}
          columnGap={styleColumnGap}
        >
          {new Array(3).fill(0).map(() => {
            return <Skeleton h={"14rem"} w={"100%"} />;
          })}
        </Grid>
        <Grid
          templateColumns={styleTemplateColumns}
          rowGap={"2rem"}
          columnGap={styleColumnGap}
        >
          {new Array(6).fill(0).map(() => {
            return <Skeleton h={"28rem"} w={"100%"} />;
          })}
        </Grid>
      </>
    );
  };

  const getPageContent = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="FrioaiHomePage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "4rem" }}
          maxW={"1500px"}
        >
          <Box>
            <Text fontSize="5xl" fontWeight={700}>
              Storage
            </Text>
          </Box>
          <Box>
            <Text fontSize="3xl" fontWeight={700} marginBottom={"1rem"}>
              Current Usage
            </Text>
            <Flex flexDir={"column"} gap={"2rem"} paddingBottom={"2rem"}>
              {loadingCatalog || loadingCustomer || loadingStorage ? (
                renderSkeletons()
              ) : (
                <>
                  {renderProducts()}
                  {renderPalets()}
                </>
              )}
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <ManagementPage
      title={"Home"}
      path={""}
      isLoading={false}
      previousLocation={""}
    >
      {getPageContent()}
    </ManagementPage>
  );
};

export const HomePage = withAuthProtection(Page);
