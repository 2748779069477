import { Box, Text, Flex, Grid, Image } from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import "./PastDetailsPage.scss";
import { IStoragePast } from "../../data/product";
import { PageNotFoundPage } from "../PageNotFound/PageNotFound";
import { useLocation } from "react-router-dom";
import { ManagementPage } from "../../components/commons/ManagementPage/ManagementPage";

const styleTemplateColumns = {
  base: "repeat(1, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

const styleColumnGap = {
  base: "1rem",
  xl: "2.5rem",
  "2xl": "5rem",
};

const Page = () => {
  const { state } = useLocation();
  const unit_id: string | null = state?.unit_id;
  const pallet: IStoragePast | null = state?.pallet;

  const getTitle = () => {
    if (!unit_id) {
      return;
    }
    if (unit_id.charAt(0) === "D") {
      return "Dry Storage";
    } else if (unit_id.charAt(0) === "F") {
      return "Frozen Storage";
    } else if (unit_id.charAt(0) === "R") {
      return "Refrigerated Storage";
    } else {
      return "Storage";
    }
  };

  const getPhoto = (currentPhoto: string) => {
    let photo = currentPhoto;
    if (!currentPhoto.includes("data:image")) {
      photo = "data:image/jpeg;base64," + photo;
    }
    return photo;
  };

  const getColor = () => {
    if (!unit_id) {
      return;
    }
    if (unit_id.charAt(0) === "D") {
      return "#A8890F";
    } else if (unit_id.charAt(0) === "F") {
      return "#1CAAF0";
    } else if (unit_id.charAt(0) === "R") {
      return "#C41FBF";
    } else {
      return "black";
    }
  };

  const hasPhoto = () => {
    if (!pallet?.photo) {
      return false;
    }
    if (Array.isArray(pallet.photo)) {
      return pallet.photo.length > 0;
    }
    return true;
  };

  const hasDocument = () => {
    if (!pallet?.document) {
      return false;
    }
    if (Array.isArray(pallet.document)) {
      return pallet.document.length > 0;
    }
    return true;
  };

  const getPageContent = () => {
    return pallet && unit_id ? (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box className="DetailsPage" gap={{ base: "2rem", xl: "4rem" }}>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={{
              base: "wrap",
              md: "nowrap",
            }}
            w={"100%"}
          >
            <Flex gap={"1rem"} alignItems={"center"} w={"100%"}>
              <Text fontSize={"4xl"}>{unit_id}</Text>
              <Flex
                alignItems={"center"}
                border={`1px solid ${getColor()}`}
                color={getColor()}
                padding={"0.5rem"}
                height={"2.5rem"}
                fontWeight={600}
              >
                {getTitle()}
              </Flex>
            </Flex>
            <Flex flexDir={"column"}>
              <Text fontSize={"xl"} whiteSpace={"nowrap"}>
                Discharge Date:{" "}
                {new Date(pallet.discharge_date).toLocaleString()}
              </Text>
              <Text fontSize={"xl"} whiteSpace={"nowrap"}>
                Entry Date: {new Date(pallet.entry_date).toLocaleString()}
              </Text>
            </Flex>
          </Flex>
          <Flex w={"100%"}>
            <Text fontSize={"2xl"}>{pallet.description}</Text>
          </Flex>
          <Flex w={"100%"} flexDir={"column"} gap={"2rem"}>
            <Text fontSize={"4xl"}>{hasPhoto() ? "Photos" : "No Photos"}</Text>
            <Grid
              templateColumns={styleTemplateColumns}
              rowGap={"2rem"}
              columnGap={styleColumnGap}
            >
              {pallet.photo &&
                (typeof pallet.photo === "string" ? (
                  <Image
                    src={getPhoto(pallet.photo)}
                    w={"400px"}
                    h={"250px"}
                    objectFit={"fill"}
                  />
                ) : (
                  (pallet.photo as string[]).map((photo: string) => {
                    return (
                      <Image
                        src={getPhoto(photo)}
                        w={"100%"}
                        h={"250px"}
                        objectFit={"fill"}
                      />
                    );
                  })
                ))}
            </Grid>
          </Flex>
          <Flex w={"100%"} flexDir={"column"} gap={"2rem"}>
            <Text fontSize={"4xl"}>
              {hasDocument() ? "Documents" : "No Documents"}
            </Text>
            <Grid
              templateColumns={styleTemplateColumns}
              rowGap={"2rem"}
              columnGap={styleColumnGap}
            >
              {pallet.document &&
                (Array.isArray(pallet.document) ? (
                  pallet.document.map((document) => {
                    return (
                      <Flex className="DetailsPageDocumentContainer">
                        <a
                          style={{ fontSize: "1rem" }}
                          download={document.documentName}
                          href={document.document}
                        >
                          {document.documentName}
                        </a>
                      </Flex>
                    );
                  })
                ) : (
                  <Flex className="DetailsPageDocumentContainer">
                    <a
                      style={{ fontSize: "1rem" }}
                      download={pallet.document.documentName}
                      href={pallet.document.document}
                    >
                      {pallet.document.documentName}
                    </a>
                  </Flex>
                ))}
            </Grid>
          </Flex>
          <Flex
            w={"100%"}
            flexWrap={{
              base: "wrap",
              md: "nowrap",
            }}
          >
            <Flex
              w={"100%"}
              flexDir={"column"}
              gap={"2rem"}
              paddingBottom={"2rem"}
            >
              <Text fontSize={"4xl"}>Signature</Text>
              <Image src={pallet.signature} w={"9.375rem"} h={"9.375rem"} />
            </Flex>
            {pallet.discharge_signature && (
              <Flex
                w={"100%"}
                flexDir={"column"}
                gap={"2rem"}
                paddingBottom={"2rem"}
              >
                <Text fontSize={"4xl"}>Discharge Signature</Text>
                <Image
                  src={pallet.discharge_signature}
                  w={"9.375rem"}
                  h={"9.375rem"}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      </Box>
    ) : (
      <PageNotFoundPage />
    );
  };

  return (
    <ManagementPage
      title={"History Detail"}
      path={""}
      isLoading={false}
      previousLocation={"history"}
    >
      {getPageContent()}
    </ManagementPage>
  );
};

export const PastDetailsPage = withAuthProtection(Page);
