import { Box, Text, Image, Button, Grid } from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import "./LocationPage.scss";
import { LocationFinder } from "./components/LocationFinder";
import { localImages } from "../../assets/images/localImages";
import { configuration } from "../../../config/configuration";

const Page = () => {
  return (
    <Box
      className="FrioaiHomePage"
      display={"flex"}
      flexDirection={"column"}
      gap={{ base: "2rem", xl: "4rem" }}
      overflowY={"auto"}
      h={"100vh"}
      maxW={"1500px"}
    >
      <Box flexGrow={1} paddingBottom={"2rem"}>
        <Text fontSize="3xl" fontWeight={700}>
          Location
        </Text>
        <Grid
          h="100%"
          gap={"2rem"}
          templateColumns={{
            base: "repeat(1, 1fr)",
            lg: "repeat(2, 1fr)",
          }}
        >
          <Box h="100%" w={"100%"} className="FrioaiHomePageMap">
            <LocationFinder
              data={{
                lat: Number(configuration.businessMetadata.lat),
                lng: Number(configuration.businessMetadata.lng),
              }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            gap={"2rem"}
            h="100%"
            w={"100%"}
          >
            <Box h="45%">
              <Image
                src={localImages.Warehouse}
                width={"100%"}
                h="100%"
                borderRadius={"1rem"}
              />
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              bg="#DD6B20"
              h="55%"
              gap={"1rem"}
              borderRadius={"1rem"}
            >
              <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
                <Text color="white" fontWeight={700}>
                  {configuration.businessMetadata.direction}
                </Text>
                <Text color="white" fontWeight={700}>
                  Open 6am to 10pm
                </Text>
              </Box>
              <a
                href={`http://maps.google.com/?q=${configuration.businessMetadata.direction}`}
              >
                <Button
                  variant={"outline"}
                  color="white"
                  border={"1px solid white"}
                >
                  Get Directions
                </Button>
              </a>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export const LocationPage = withAuthProtection(Page);
